:root {
  /*general style*/
  --theme-color: #00B76E;
  --default-text-color: #424243;
  --main-background-color: #F3F2F3;
  --error-color: #F41D59;
  /*header logo style*/
  --header-logo-height: 9vh;
  --header-logo-width: 16vh;
  --header-logo-margin-left: 7vh;
  /*profile container style*/
  --profile-hover-color: rgba(0, 183, 110, 0.3);
}
.App {
  text-align: center;
  /*overflow: scroll;*/
}

.App-logo {
  /* animation: App-logo-spin infinite 20s linear; */
  height: 25vmin;
  /* pointer-events: none; */
}

.App-logo-header {
  height: var(--header-logo-height);
  width: var(--header-logo-width);
  margin-left: var(--header-logo-margin-left);
}

.LogoutButtonContainer {
  height: 100%;
  width: 10%;
  float: right;
}

.LogoutTextContainer {
  font-family: kelson2;
  font-size: 20px;
  text-align: center;
  margin-top: 5% !important;
  color: var(--theme-color);
}

.mainHeader {
  /* background-color: #F3F2F3; */
  position: relative;
  min-height: 15vh;
  min-width: 100%;
  display: flex;
  /* margin-top: 5vh; */
  flex-direction: row;
  align-items: center;
  border: 1px solid silver;
  overflow: auto;
}

.profilesHeader {
  /* background-color: #F3F2F3; */
  min-height: 5vh;
  min-width: 100%;
  display: flex;
  /* margin-top: 5vh; */
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid silver;
  justify-content: center;
}

.ProfilesHeaderTextActive {
  font-size: 20px; 
  font-family: kelson2; 
  color: var(--theme-color);
}

.ProfilesHeaderText{
  font-size: 20px; 
  font-family: kelson2; 
  color: var(--default-text-color);
}

.LoginInput {
  background-color: var(--main-background-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 50vh;
  min-width: 100vh;
}

.SpendendosenHeader {
  font-family: kelson1;
  font-size: 40px;
  float: left;
  color: var(--theme-color);
  margin-top: 2% !important;
}

.ProfileListNameText {
  font-family: kelson1; 
  justify-content: center;
  text-align: center;
  color: var(--theme-color);
}

.ProfileContainer {
  display: flex;
  flex-direction: row;
  height: 80px;
  width: 100%;
  border-bottom: 1px solid silver;
  justify-content: center
}


.ProfileUpdateContainer {
  height: 50vh;
  width: 80%;
  margin-left: 6%;
  margin-top: 2%
}

.ProfileUpdateNameProperty {
  height: 20%;
  width: 100%;
}
.ProfileUpdateNamePropertyEdit {
  height: 20%;
  width: 100%;
  display: flex;
  flex-direction: row
}

.ProfileUpdateNewPayment {
  height: 18%;
  width: 100%;
  display: inline-flex;
}

.ProfileUpdatePaymentValueContainer {
  height: 48%;
  width: 60%;
  display: inline-flex;
}

.ProfileUpdatePurchaseValueContainer {
  height: 100%;
  width: 60%;
  display: inline-flex;
  justify-content: space-between;
}

.ProfileDeleteContainer {
  height: 100%;
  width: 60%;
}

.ProfileUpdateNewPurchase {
  height: 18%;
  width: 100%;
  display: inline-flex;
}

.ProfileUpdateChangeFloorContainer {
  height: 100%;
  width: 60%;
  display: inline-flex;
}

.ProfileUpdateActiveContainer {
  height: 100%;
  width: 60%;
}

.SwitchContainer {
  float: left;
}

.ProfileUpdateChangeFloor {
  height: 18%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.ProfileUpdateActive {
  height: 18%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.ProfileUpdateDelete {
  height: 18%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.ProfileTextContainer {
  height: 100%;
  width: 40%;
}

.NewProfileTextContainer {
  height: 100%;
  width: 40%;
}
.NewProfilePinTextContainer {
  height: 100%;
  width: 30%;
}

.NewProfileSelectContainer {
  height: 100%;
  width: 70%;
  display: inline-flex
}
.NewProfileSelectDigitContainer {
  height: 100%;
  width: 70%;
  align-items: center;
  justify-content: center
}
.TwentyPercentage {
  height: 100%;
  width: 20%;
}
.SixtyPercentage {
  height: 100%;
  width: 60%;
}
.SixtyPercentageModal {
  height: 100%;
  width: 60%;
  justify-content: center;
  align-items: center;
  display: flex
}

.NewProfilePinInputContainer {
  height: 100%;
  width: 80%;
}

.NewProfile {
  display: flex;
  flex-direction: column;
  min-height: 85vh;
  min-width: 100vh;
}

.NewProfileInputContainer {
  height: 40vh;
  width: 100vh;
  margin-left: 5%;
  margin-top: 6%
}
.NewRoomInputContainer {
  height: 20vh;
  width: 100vh;
  margin-left: 5%;
  margin-top: 6%
}
.ChangablePinContainer {
  height: 10vh;
  width: 100vh;
  /* margin-left: 5%; */
}
.ChangablePinContainerHigh {
  height: 40vh;
  width: 100vh;
  /* margin-left: 5%; */
}

.NewProfileAddButtonText {
  font-family: kelson1;
  color: var(--main-background-color);
  font-size: 20px;
}

.NewProfileGeneralText {
  font-family: kelson2;
  float: left;
  font-size: 20px;
}

.PinContainer {
  display: inline-block;
  width: 100vh;
  height: 50vh;
  margin-left: 5%
}

.PinInputContainer {
  height: 10vh;
  width: 80vh;
  align-items: center;
  justify-content: center;
}

.PinInputTwentyFive {
  height: 25%;
  width: 60%;
}

.PinInputTwenty {
  height: 20%;
  width: 60%;
}

.NewProfilePinContainer {
  height: 100%;
  width: 100%;
  display: inline-flex;
  justify-content: space-between
}

.NewProfileButtonContainer {
  margin-bottom: 2%;
}

.DigitButton {
  width: 25%; 
  height: 70px;
  background-color: var(--main-background-color);
  border: 2px solid var(--theme-color);
}

.DigitTransparentButton {
  width: 25%; 
  height: 70px;
  background-color: transparent;
  border: transparent;
}

.DigitEraseButton {
  width: 30px;
  height: 30px;
}

.DigitButtonGroup {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 80%;
  justify-content: space-between;
  align-self: center;
  margin-left: 10%;
}

.DigitButtonText {
  color: var(--theme-color);
  font-size: 35px;
  text-align: center;
}

.FirstDigitsContainer {
  height: 10vh;
  width: 80vh;
  align-items: center;
  justify-content: center;
  display: block
}

.firstButtons {
  width: 50%;
  height: 10vh;
  border: 1px solid var(--theme-color);
  align-self: center
}

.SecondDigitsContainer {
  height: 10vh;
  width: 80vh;
  align-items: center;
  justify-content: center;
}

.ThirdDigitsContainer {
  height: 10vh;
  width: 80vh;
  align-items: center;
  justify-content: center;
}

.FourthDigitsContainer {
  height: 10vh;
  width: 80vh;
  align-items: center;
  justify-content: center;
}

.NewProfileFirstName {
  height: 25%;
  width: 100%;
  display: inline-flex;
  justify-content: space-between
}
.NewRoomName {
  height: 50%;
  width: 100%;
  display: inline-flex;
  justify-content: space-between
}

.NewProfileLastName {
  height: 25%;
  width: 100%;
  display: inline-flex;
  justify-content: space-between
}
.NewProfilePinTextInput {
  height: 25%;
  width: 100%;
  display: inline-flex;
  justify-content: space-between
}
.NewProfilePinDigits {
  height: 75%;
  width: 100%;
  display: inline-flex;
  justify-content: space-between
}

.ProfileTextValue {
  float: right;
  font-size: 22px;
  font-family: kelson2;
  color: var(--theme-color);
}
.ProfileTextValueMail {
  float: right;
  font-size: 22px;
  font-family: kelson2;
  color: var(--theme-color);
}

.RecordButton {
  display: block;
  background-color: #fff;
  width: 120px;
  height: 100%;
  border: 1px solid var(--theme-color);
  border-color: var(--theme-color);
  border-radius: 2px
}

.RecordButton:hover {
  background-color: var(--theme-color);
  border: 1px solid var(--theme-color);
  border-color: var(--theme-color);
  border-radius: 2px
}

.RecordButton:hover .RecordButtonText {
  color: #fff;
  font-size: 15px;
  font-family: kelson2
}

.RecordButtonText {
  color: var(--theme-color);
  font-size: 15px;
  font-family: kelson2
}

.ProfileValueContainer {
  height: 100%;
  width: 60%;
  display: inline-flex
}

.ProfileControl {
  height: 13vh;
  width: 95vh;
}

.ProfileUpdateProperty {
  height: 18%;
  width: 100%;
  display: inline-flex;
}

.ProfileUpdateBlankProperty {
  height: 15%;
  width: 100%;
  display: inline-flex;
}

.ProfileTransactionHistory {
  height: 100%;
  width: 100%;
  display: inline-block;
}

.ProfileUpdateBalanceValue {
  width: 22%;
  margin-top: 1%;
}

.ProfileUpdateNameSurnameContainer {
  width: 18%;
  margin-top: 1%;
}

.ProfileUpdateLastPaymentContainer {
  width: 28%;
  margin-top: 1%;
  display: inline-flex;
}

.ProfileUpdateLastPaymentText {
  font-family: kelson1;
  font-size: 23px;
  color: var(--default-text-color);
}

.ProfileUpdateNameText {
  font-family: kelson1;
  font-size: 25px;
  color: var(--default-text-color);
  float: left;
}

.ProfileUpdateBalanceValueText {
  font-family: kelson1;
  justify-content: center;
  font-size: 25px;
  text-align: center;
  color: var(--default-text-color);
  margin-top: 6%;
  float: right;
}

.ProfileUpdateCurrentMonthText {
  font-family: kelson1;
  justify-content: center;
  font-size: 25px;
  text-align: center;
  color: var(--default-text-color);
  float: right;
}

.ProfileUpdatePrevMonthText {
  font-family: kelson1;
  justify-content: center;
  font-size: 25px;
  text-align: center;
  color: var(--default-text-color);
  float: right;
}

.ProfileUpdateCurrentMonthContainer {
  width: 25%;
  margin-top: 1%;
  display: inline-flex
}

.ProfileUpdatePrevMonthContainer {
  width: 25%;
  margin-top: 1%;
  display: inline-flex;
}

.ProfileUpdate {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vh;
}

.ProfileContainer:hover {
  background-color: var(--profile-hover-color);
}

.Dashboard {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  min-width: 100vh;
}

.DashboardInnerDiv {
  flex: 5 1 0%;
  flex-direction: column;
  align-items: center;
  margin-top: 5%;
  margin-left: 4%;
}

.DashboardHeaderDiv {
  width: 100%;
  height: 7%;
  margin-top: 3%;
}

.DashboardHeader {
  font-family: kelson1;
  float: left;
  margin-left: 5%;
  font-size: 35px;
  color: var(--default-text-color);
}

.DashboardLabelText {
  font-family: kelson1;
  float: left;
  margin-left: 5%;
  font-size: 25px;
  color: var(--default-text-color);
}

.DashboardValueTheme {
  font-family: kelson2;
  float: right;
  margin-right: 12%;
  font-size: 25px;
  color: var(--theme-color);
}

.DashboardValueWarning {
  font-family: kelson2;
  float: right;
  margin-right: 12%;
  font-size: 25px;
  color: #f26522;
}

.PaymentContainer {
  display: flex;
  flex-direction: row;
  clear: both;
  height: 70px;
  width: 100%;
  border-bottom: 1px solid silver;
}

.PaymentText {
  margin-left: 25%;
  font-family: kelson2;
  font-size: 20px;
  color: var(--theme-color);
  text-align: center;
  float: right;
}

.PaymentBalanceText {
  margin-top: 0%;
  font-family: kelson2;
  font-size: 20px;
  color: var(--theme-color);
  text-align: right;
}

.PaymentlastText {
  margin-top: 1%;
  font-family: kelson2;
  font-size: 20px;
  color: var(--theme-color);
  text-align: center;
  float: right;
}

.PaymentPrevText {
  margin-top: 1%;
  font-family: kelson2;
  font-size: 20px;
  color: var(--theme-color);
  text-align: center;
  float: right;
}

.DrinkContainer {
  display: flex;
  flex-direction: row;
  min-height: 15vh;
  min-width: 100%;
  align-items: center
}

.DrinkNameText {
  font-family: kelson2;
  font-size: 20px;
  color: var(--default-text-color);
  float: left;
  margin-top: 1%;
  text-align: center;
}

.Prices {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  min-width: 100vh;
}

.PricesCurrentText {
  font-family: kelson2;
  float: left;
  margin-left: 45%;
  font-size: 20px;
  color: var(--theme-color);
}

.PricesNewText {
  font-family: kelson2;
  float: left;
  margin-left: 14%;
  font-size: 20px;
  color: var(--theme-color);
}

.PricesCostText {
  float: right;
  margin-top: 1%;
  font-family: kelson2;
  margin-right: 31%;
  font-size: 20px;
  color: var(--default-text-color);
  text-align: center;
}

.AnalysisContainer {
  height: 50vh;
  width: 90%;
  margin-left: 6%;
  margin-top: 2%
}

.AnalysisExportContainer {
  height: 100%;
  width: 40%;
}

.AnalysisSummaryContainer {
  height: 100%;
  width: 20%;
}

.AnalysisSummaryText {
  font-family: kelson2;
  font-size: 22px;
  color: var(--theme-color);
  float: right;
  margin-top: 1%;
}

.App-header {
  background-color: var(--main-background-color);
  min-height: 50vh;
  min-width: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.BackButton {
  width: 20%;
  height: 18%;
}

.backButtonImg {
  width: 50px;
  height: 50px;
  float: left;
}

.InactiveContainer {
  width: 30%;
  height: 100%;
  margin-top: 1%
}

.InactiveText {
  font-family: kelson2;
  justify-content: center;
  font-size: 15px;
  text-align: center;
  align-self: center;
  color: #d50000;
  float: right,
}

.ProfilePersonValueContainer {
  width: 62%;
  height: 70px;
  display: inline-flex;
  margin-top: 0.5%
}

.ProfileFirstLinesContainer {
  width: 100%;
  display: inline-flex;
}
.ProfileFirstLinesMailContainer {
  width: 100%;
  display: inline-flex;
}

.ProfileCurrentMonthHeaderContainer {
  width: 32%;
}

.ProfileNameAndSurname {
  height: 100%;
  width: 100%;
  display: inline-flex;
}

.HeaderButton {
  width: 160px;
  height: 48px;
  display: inline-flex;
}

.logoutContainer {
  width: 100%;
  height: 5vh;
}

.PaddingTop {
  top: 1%
}

.ThematicGeneralText {
  font-family: kelson2;
  font-size: 25px;
  color: var(--theme-color);
  float: left;
  margin-top: 1%;
}

.SelectedProfileInfoText {
  font-family: kelson2; 
  float: left;
  font-size: 22px;
  color: var(--theme-color); 
  margin-top: 1%;
}

.DeviceLabelText {
  font-family: kelson2;
  font-size: 25px;
  color: var(--theme-color);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}